<template>
        <!-- Hero Section Begin -->
    <section class="hero-section">
        <div v-if="banners.length > 0">
            <carousel class="hero-items" :autoplay="true" :items="1" :nav="false" :autoplaySpeed="1000" :autoplayTimeout="10000" style="text-align: left;">
            <div class="single-hero-items set-bg" v-for="itemBanner in banners" v-bind:key="itemBanner.id" :style="{backgroundImage: 'url(' + itemBanner.photo + ')'}">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <span>Komputer,Laptop</span>
                            <h1>{{ itemBanner.name }}</h1>
                            <p v-html="itemBanner.description"></p>
                            <a href="#" class="primary-btn">{{ itemBanner.button }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </carousel>
        </div>
        <div v-else>
            <p>Data tidak ditemukan.</p>
        </div>
    </section>
    <!-- Hero Section End -->
</template>

<script>
import carousel from 'vue-owl-carousel'
import axios from 'axios';
export default {
    name: 'CarouselHome',
    components: {
        carousel
    },
    data(){
        return {
            banners: []
        };
    },
    mounted(){
        axios
            .get("https://admin-store.arifamuhsy.com/api/banners/list")
            .then(res=>(this.banners = res.data.data))
            .catch(err => console.log(err));
    }
}
</script>

<style scoped>

</style>