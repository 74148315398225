<template>
     <!-- Women Banner Section Begin -->
    <section class="women-banner spad">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 mt-5" v-if="products.length > 0">
                    <carousel class="product-slider" :dots="false" :items="3" :autoplay="true" :nav="false">
                        <div class="product-item" v-for="itemProduct in products" v-bind:key="itemProduct.id">
                            <div class="pi-pic">
                                <img v-bind:src="itemProduct.galleries[0].photo" alt="" />
                                <ul>
                                    <li class="w-icon active">
                                        <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)" href="#"><i class="icon_bag_alt"></i></a>
                                    </li>
                                    <router-link v-bind:to="'/product/'+itemProduct.id"><li class="quick-view"><a href="#">+ Quick View</a></li></router-link>
                                </ul>
                            </div>
                            <div class="pi-text">
                                <div class="catagory-name">{{ itemProduct.type }}</div>
                                <a href="#">
                                    <h5>{{ itemProduct.name }}</h5>
                                </a>
                                <div class="product-price">
                                    <!-- $14.00
                                    <span>$20.00</span> -->
                                    Rp {{ formatNumber(itemProduct.price)  }}
                                </div>
                            </div>
                        </div>
                    </carousel>
                </div>
                <div class="col-lg-12 mt-5" v-else>
                    <p>Data tidak ditemukan.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- Women Banner Section End -->
</template>

<script>
import carousel from 'vue-owl-carousel';
import axios from 'axios';
export default {
    name: 'ItemCarousel',
    components: {
        carousel
    },
    data(){
        return {
            products: [],
            keranjangUser: []
        };
    },
    mounted(){
        if(localStorage.getItem('keranjangUser')){
            try{
                this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
            }
            catch (e) {
                localStorage.removeItem('keranjangUser');
            }
        }
        axios
            .get("https://admin-store.arifamuhsy.com/api/products/list")
            .then(res=>(this.products = res.data.data.data))
            .catch(err => console.log(err));
    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatNumber(value){
            return new Intl.NumberFormat('id-ID').format(value);
        },
        saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct){
            var productStored = {
                "id": idProduct,
                "name": nameProduct,
                "price": priceProduct,
                "photo": photoProduct
            }
            this.keranjangUser.push(productStored);
            const parsed = JSON.stringify(this.keranjangUser);
            localStorage.setItem('keranjangUser', parsed);
        }
    }
}
</script>

<style scoped>
    .product-item {
        margin-right: 20px;
    }
</style>