<template>
    <div class="instagram-photo">
        <div class="insta-item set-bg" style="background: url('img/insta-1.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background: url('img/insta-2.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background: url('img/insta-3.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background: url('img/insta-4.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background: url('img/insta-5.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background: url('img/insta-6.jpg')">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">amunix.id</a></h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstaGallery"
}
</script>

<style scoped>

</style>