<template>
  <div class="product">
    <HeaderWeb />
    <!-- Breadcrumb Section Begin -->
    <div class="breacrumb-section text-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text product-more">
                        <router-link to="/"><i class="fa fa-home"></i> Home</router-link>
                        <span>Detail</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section Begin -->

    <!-- Product Shop Section Begin -->
    <section class="product-shop spad page-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="product-pic-zoom">
                                <img class="product-big-img" :src="pict_default" alt="" />
                            </div>
                            <div class="product-thumbs" v-if="productDetails.galleries.length > 0">
                                <carousel class="product-thumbs-track ps-slider" :dots="false" :nav="false">
                                    <div
                                    v-for="itemProduct in productDetails.galleries" 
                                    :key="itemProduct.id" 
                                    class="pt" 
                                    @click="changeImage(itemProduct.photo)" 
                                    :class="itemProduct.photo == pict_default ? 'active' : ''">
                                        <img :src="itemProduct.photo" alt="" />
                                    </div>
                                </carousel>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="product-details">
                                <div class="pd-title text-left">
                                    <span>{{ productDetails.type }}</span>
                                    <h3>{{ productDetails.name }}</h3>
                                </div>
                                <div class="pd-desc text-justify">
                                    <p v-html="productDetails.description"></p>
                                    <h4>Rp {{ formatNumber(productDetails.price) }}</h4>
                                </div>
                                <div class="quantity">
                                    <router-link to="/carts">
                                        <a @click="saveKeranjang(productDetails.id, productDetails.name, productDetails.price, productDetails.galleries[0].photo)" href="#" class="primary-btn pd-cart">Add To Cart</a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Product Shop Section End -->
    <!-- <RelatedProduct :routeId="productDetails.id" :productType="productDetails.type"/> -->
    <FooterWeb />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderWeb from '@/components/Header.vue';
import FooterWeb from '@/components/Footer.vue';
// import RelatedProduct from '@/components/RelatedProduct';
import carousel from 'vue-owl-carousel';
import axios from 'axios';

export default {
  name: 'ProductWeb',
  components: {
    HeaderWeb,
    carousel,
    // RelatedProduct,
    FooterWeb
  },
  data(){
      return{
          pict_default: "img/products/acer-1.png",
          productDetails: [],
          keranjangUser: []
      }
  },
  methods:{
      changeImage(urlImage){
          this.pict_default = urlImage;
      },
      setDataPicture(data) {
        this.productDetails = data;

        this.pict_default = data.galleries[0].photo;
      },
      formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatNumber(value){
            return new Intl.NumberFormat('id-ID').format(value);
        },
        saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct){
            var productStored = {
                "id": idProduct,
                "name": nameProduct,
                "price": priceProduct,
                "photo": photoProduct
            }
            this.keranjangUser.push(productStored);
            const parsed = JSON.stringify(this.keranjangUser);
            localStorage.setItem('keranjangUser', parsed);
        }
  },
    mounted(){
        if(localStorage.getItem('keranjangUser')){
            try{
                this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
            }
            catch (e) {
                localStorage.removeItem('keranjangUser');
            }
        }
        axios
            .get("https://admin-store.arifamuhsy.com/api/products/list", {
                params: {
                    id: this.$route.params.id
                }
            })
            .then(res=>(this.setDataPicture(res.data.data)))
            .catch(err => console.log(err));
    }
}
</script>

<style scoped>
 .product-thumbs .pt {
     margin-right: 10px;
 }
</style>