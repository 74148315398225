<template>
    <div class="home">
        <HeaderWeb />
        <CarouselHome />
        <ItemCarousel />
        <InstaGallery />
        <PartnerLogo />
        <FooterWeb shopName="Arishoppppp" />
    </div>
</template>

<script>
import HeaderWeb from '@/components/Header.vue'
import CarouselHome from '@/components/CarouselHome.vue'
import ItemCarousel from '@/components/ItemCarousel.vue'
import InstaGallery from '@/components/InstaGallery.vue'
import PartnerLogo from '@/components/PartnerLogo.vue'
import FooterWeb from '@/components/Footer.vue'

export default{
    name: 'HomeWeb',
    components: {
        HeaderWeb,
        CarouselHome,
        ItemCarousel,
        InstaGallery,
        PartnerLogo,
        FooterWeb
    }
}
</script>