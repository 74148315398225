<template>
    <div class="shopping">
        <HeaderWeb />
        <!-- Breadcrumb Section Begin -->
    <div class="breacrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text product-more text-left">
                        <router-link to="/"><i class="fa fa-home"></i> Home</router-link>
                        <span>Shopping Cart</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumb Section Begin -->

    <!-- Shopping Cart Section Begin -->
    <section class="shopping-cart spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="cart-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th class="p-name text-center">Product Name</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="keranjangUser.length > 0">
                                        <tr v-for="keranjang in keranjangUser" :key="keranjang.id">
                                            <td class="cart-pic first-row">
                                                <img :src="keranjang.photo" />
                                            </td>
                                            <td class="cart-title first-row text-center">
                                                <h5>{{keranjang.name}}</h5>
                                            </td>
                                            <td class="p-price first-row">Rp {{keranjang.price}}</td>
                                            <td @click="removeItem(keranjang.id)" class="delete-item">
                                                <button class="btn">
                                                    <i class="material-icons">
                                                        close
                                                    </i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td>Keranjang kosong.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <h4 class="mb-4 text-left">
                                Informasi Pembeli:
                            </h4>
                            <div class="user-checkout text-left">
                                <form>
                                    <div class="form-group">
                                        <label for="namaLengkap">Nama lengkap</label>
                                        <input type="text" class="form-control" id="namaLengkap" aria-describedby="namaHelp" placeholder="Masukan Nama" v-model="customerInfo.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="namaLengkap">Email Address</label>
                                        <input type="email" class="form-control" id="emailAddress" aria-describedby="emailHelp" placeholder="Masukan Email" v-model="customerInfo.email">
                                    </div>
                                    <div class="form-group">
                                        <label for="namaLengkap">No. HP</label>
                                        <input type="text" class="form-control" id="noHP" aria-describedby="noHPHelp" placeholder="Masukan No. HP" v-model="customerInfo.number">
                                    </div>
                                    <div class="form-group">
                                        <label for="alamatLengkap">Alamat Lengkap</label>
                                        <textarea class="form-control" id="alamatLengkap" rows="3" v-model="customerInfo.address"></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="proceed-checkout text-left">
                                <ul>
                                    <li class="subtotal">ID Transaction <span>#SH12000</span></li>
                                    <li class="subtotal mt-3">Subtotal <span>Rp {{totalHarga}}</span></li>
                                    <li class="subtotal mt-3">Pajak <span>Rp {{totalHarga*10/100}}</span></li>
                                    <li class="subtotal mt-3">Total Biaya <span>Rp {{totalHarga+(totalHarga*10/100)}}</span></li>
                                    <li class="subtotal mt-3">Bank Transfer <span>Mandiri</span></li>
                                    <li class="subtotal mt-3">No. Rekening <span>2222 5555 1111</span></li>
                                    <li class="subtotal mt-3">Nama Penerima <span>Arishop</span></li>
                                </ul>
                                <!-- <router-link to="/success"> -->
                                    <a @click="checkout()" href="#" class="proceed-btn">I ALREADY PAID</a>
                                <!-- </router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Shopping Cart Section End -->
<FooterWeb />
    </div>
</template>
<script>
import HeaderWeb from '@/components/Header.vue'
import FooterWeb from '@/components/Footer.vue'
import axios from 'axios'
export default {
    name: "ShoppingCarts",
    components: {
        HeaderWeb,
        FooterWeb,
    },
    data(){
        return{
            keranjangUser: [],
            customerInfo: {
                name: '',
                email: '',
                number: '',
                address: ''
            }
        };
    },
    methods:{
        removeItem(idx){
            let keranjangUserStorage = JSON.parse(localStorage.getItem('keranjangUser'));
            let itemKeranjangUserStorage = keranjangUserStorage.map(itemKeranjangUserStorage => itemKeranjangUserStorage.id);
            let index = itemKeranjangUserStorage.findIndex(id => id == idx);
            this.keranjangUser.splice(index, 1);

            const parsed = JSON.stringify(this.keranjangUser);
            localStorage.setItem('keranjangUser',parsed);
        },
        removeAllItem(index){
            this.keranjangUser.splice(index);
            const parsed = JSON.stringify(this.keranjangUser);
            localStorage.setItem('keranjangUser', parsed);
        },
        checkout(){
            let productIds = this.keranjangUser.map(function(product){
                return product.id
            });

            let checkoutData = {
                'name': this.customerInfo.name,
                'email': this.customerInfo.email,
                'number': this.customerInfo.number,
                'address': this.customerInfo.address,
                'transaction_total': this.totalHarga+(this.totalHarga*10/100),
                'transaction_status': "PENDING",
                'transaction_details': productIds,
            };

            axios
            .post("https://admin-store.arifamuhsy.com/api/checkout", checkoutData)
            .then(() => this.$router.push("success"))
            .catch(err => console.log(err));

            this.removeAllItem();
        },
    },
    mounted(){
        if(localStorage.getItem('keranjangUser')){
            try{
                this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
            }
            catch (e) {
                localStorage.removeItem('keranjangUser');
            }
        }
    },
    computed: {
        totalHarga(){
            return this.keranjangUser.reduce(function(items, data){
                return items + data.price;
            }, 0);
        }
    }
}
</script>
<style scoped>

</style>